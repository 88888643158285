import {
  useDispatch,
  useSelector
} from 'react-redux';
import { setSearchTags } from '../../reduxhandler/articleredux/articleSlice';
import { listTags } from './listTags'; // Vérifiez le chemin
import styled from 'styled-components';
import SearchKeyComponent from './SearchKeyComponent';
import { variablesGlobals } from '../../style/variables';

const TagsContainer = styled.div`
  display: flex;
  position: fixed;
  right: 20px;
  top: 20px;
  flex-direction: column;
  width: 100px;
  z-index: 20000;

  & button {
    border-radius: 15px;
    padding: 2px 5px;
    background-color: ${variablesGlobals.darkGreyBack};
    border: none;
    margin: 2px;
    cursor: pointer;
  }
  & input {
    border-radius: 15px;
  }

  @media (min-width: 200px) and (max-width: 800px) {
    width: 50%;
    flex-wrap: wrap;
    top: 150px;
    left: 50%;
    flex-direction: row;
    justify-content: space-between;
    right: auto;

    & button {
      font-size: 0.6em;
    }
  }
`;

function TagsComponent() {
  const dispatch = useDispatch();
  const selectedTags = useSelector(
    (state) => state.articleReducer.searchTags
  );

  const handleTagToggle = (tag) => {
    const updatedTags = selectedTags.includes(tag)
      ? selectedTags.filter((t) => t !== tag)
      : [...selectedTags, tag];
    dispatch(setSearchTags(updatedTags));
  };

  return (
    <TagsContainer>
      {listTags.map((tag) => (
        <button
          key={tag}
          onClick={() => handleTagToggle(tag)}
          style={{
            backgroundColor:
              selectedTags.includes(tag)
                ? variablesGlobals.lightGreyBack
                : 'white'
          }}
        >
          {tag}
        </button>
      ))}
      <SearchKeyComponent />
    </TagsContainer>
  );
}

export default TagsComponent;
