import { createSlice } from '@reduxjs/toolkit';
import dataJePenseMock from '../../data/je-pense-donc-je-cree.json';
import dataInspirationMock from '../../data/inspiration.json';

const ecritSlice = createSlice({
  name: 'ecritPagination',
  initialState: {
    pages: {
      jePense: {
        currentPage: 0,
        data: dataJePenseMock?.data
      },
      inspiration: {
        currentPage: 0,
        data: dataInspirationMock?.data
      }
    }
  },
  reducers: {
    setPage: (state, action) => {
      const { type, page } = action.payload;
      if (state.pages[type]) {
        state.pages[type].currentPage = page;
      }
    },
    setData: (state, action) => {
      const { type, data } = action.payload;
      if (state.pages[type]) {
        state.pages[type].data = data;
      }
    }
  }
});

export const { setPage, setData } =
  ecritSlice.actions;
export default ecritSlice.reducer;
