import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedMenu: ''
};

const menuSlice = createSlice(
  {
    name: 'menu',
    initialState,
    reducers: {
      menuSelected: (
        state,
        action
      ) => {
        state.selectedMenu =
          action.payload;
      }
    }
  }
);

export const {
  menuSelected,
  menuArtSelected
} = menuSlice.actions;

export default menuSlice.reducer;
