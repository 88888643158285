import React, {
  useEffect,
  useRef,
  useState
} from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { setPage } from '../../reduxhandler/ecritredux/ecritSlice';
import styled from 'styled-components';
import MenuEcrit from './MenuEcrit';
import { variablesGlobals } from '../../style/variables';
import { parseTextImage } from './parseTextImage';
import { truncateText } from '../utils/utils';
import { Helmet } from 'react-helmet-async';

const SectionStyle = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2000;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;

  a {
    color: ${variablesGlobals.darkGreyBack};
    &:hover {
      color: black;
    }
  }

  @media (min-width: 200px) and (max-width: 1200px) {
    width: 100%;
    border-radius: 15px 0 0 15px;
  }
`;
const SectionEcrite = styled.section`
  height: 800px;
  width: 55%;
  background-color: ${variablesGlobals.veryLightGreyBack};
  margin: 60px auto;
  padding: 20px;
  background-image: ${({ $backgroundImage }) =>
    $backgroundImage
      ? `url(${$backgroundImage})`
      : 'none'};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: black;
  font-family: ${variablesGlobals.fontFamilyLogo};
  border-radius: 15px;
  overflow: auto;
  font-size: 1.1em;
  top: 5px;
  white-space: pre-line;
  position: fixed;
  transition: all 2s ease-in-out;
  /* Custom scrollbar */

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(
      255,
      255,
      255,
      0.1
    ); /* Fond transparent */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(
      0,
      0,
      0,
      0.2
    ); /* Barre semi-transparente */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(
      0,
      0,
      0,
      0.3
    ); /* Couleur au survol */
  }

  @media (min-width: 200px) and (max-width: 1200px) {
    width: 95%;
    top: 100px;
    min-height: 200px;
    height: fit-content;
    max-height: 70%;
    border-radius: 35px;
    font-size: 0.7em;
  }
`;
const ScrollButton = styled.button`
  position: fixed;
  bottom: ${({ $position }) =>
    $position === 'up'
      ? '70px'
      : '0px'}; /* Positionne les boutons vers le haut et vers le bas */
  right: 50%;
  transform: translate(50%, 0);
  width: 50px;
  height: 50px;
  background-color: ${variablesGlobals.veryDarkGreyBack};
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: ${variablesGlobals.lightGreyBack};
  }

  @media (min-width: 200px) and (max-width: 1200px) {
    left: 85%;
    transform: translate(0, 0);
  }
`;
const TriangleIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  ${({ direction }) =>
    direction === 'up'
      ? 'border-bottom: 20px solid white;' /* Triangle vers le haut */
      : 'border-top: 20px solid white;'}/* Triangle vers le bas */
`;

const H3 = styled.h3`
  width: 100%;
  padding: 20px;
  border-radius: 15px;
  background-color: white;
  box-sizing: border-box;
  font-weight: 700;
  font-size: 1.5em;
  text-align: center;
`;
const Paragraphe = styled.div`
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 15px;
`;
const ParagraphePrincipal = styled.div`
  background-color: white;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  width: 100%;
  margin: auto;
  border-radius: 15px;
  font-size: 1.3em;
`;
function MemoireComponent({ type }) {
  const dispatch = useDispatch();
  const sectionRef = useRef(null);
  const [backgroundImage, setBackgroundImage] =
    useState('');

  // Adjusted selectors for the `ecritReducer` structure
  const dataState = useSelector(
    (state) =>
      state.ecritReducer.pages?.[type]?.data || []
  );
  const currentPage = useSelector(
    (state) =>
      state.ecritReducer.pages?.[type]
        ?.currentPage || 0
  );

  const [url, setUrl] = useState('');

  useEffect(() => {
    type === 'inspiration'
      ? setUrl('images/ecrits/inspiration/')
      : setUrl('images/ecrits/je_pense/');
  }, [type]);

  useEffect(() => {
    if (dataState[currentPage]) {
      const currentData = dataState[currentPage];
      setBackgroundImage(
        currentData.images?.[0]?.path || ''
      );
    }
  }, [dataState, currentPage]);

  const handleChapterClick = (index) => {
    dispatch(setPage({ type, page: index }));
  };

  const handleScroll = (direction) => {
    if (sectionRef.current) {
      const {
        scrollTop,
        scrollHeight,
        clientHeight
      } = sectionRef.current;

      if (direction === 'down') {
        if (
          scrollTop + clientHeight >=
          scrollHeight - 100
        ) {
          // At the bottom, go to the next page if available
          if (
            currentPage <
            dataState.length - 1
          ) {
            dispatch(
              setPage({
                type,
                page: currentPage + 1
              })
            );
            setTimeout(() => {
              sectionRef.current.scrollTo({
                top: 0
              });
            }, 0);
          }
        } else {
          sectionRef.current.scrollBy({
            top: 600,
            behavior: 'smooth'
          });
        }
      } else if (direction === 'up') {
        if (scrollTop <= 0) {
          // At the top, go to the previous page if available
          if (currentPage > 0) {
            dispatch(
              setPage({
                type,
                page: currentPage - 1
              })
            );
            setTimeout(() => {
              sectionRef.current.scrollTo({
                top: sectionRef.current
                  .scrollHeight
              });
            }, 0);
          }
        } else {
          sectionRef.current.scrollBy({
            top: -600,
            behavior: 'smooth'
          });
        }
      }
    }
  };
  const renderPage = (item) => {
    const {
      title,
      text,
      order,
      notes,
      images,
      textPrincipal,
      list
    } = item;

    return (
      <>
        <H3>{title}</H3>
        {textPrincipal && (
          <ParagraphePrincipal>
            {textPrincipal}
          </ParagraphePrincipal>
        )}

        {list && list.length > 0 ? (
          <div>
            {list.map((listItem, index) => (
              <div key={index}>
                {parseTextImage(listItem)}
              </div>
            ))}
          </div>
        ) : (
          parseTextImage(text, images, notes, url)
        )}

        <ParagraphePrincipal>
          {order}
        </ParagraphePrincipal>
      </>
    );
  };

  const currentData =
    dataState[currentPage] || {};

  return (
    <SectionStyle>
      {/* Helmet pour la SEO dynamique */}
      <Helmet>
        <title>{currentData.title || ''}</title>
        <meta
          name="description"
          content={
            truncateText(currentData.text) || ''
          }
        />
        <meta
          property="og:title"
          content={currentData.title || ''}
        />
        <meta
          property="og:description"
          content={
            truncateText(currentData.text) || ''
          }
        />
        <meta
          property="og:image"
          content={backgroundImage || ''}
        />
      </Helmet>
      <MenuEcrit
        chapters={dataState || []}
        onChapterClick={handleChapterClick}
      />
      <SectionEcrite
        ref={sectionRef}
        $backgroundImage={backgroundImage}
      >
        {dataState[currentPage] ? (
          renderPage(dataState[currentPage])
        ) : (
          <p>Aucune donnée disponible</p>
        )}
        <ScrollButton
          $position="up"
          onClick={() => handleScroll('up')}
        >
          <TriangleIcon direction="up" />
        </ScrollButton>
        <ScrollButton
          $position="down"
          onClick={() => handleScroll('down')}
        >
          <TriangleIcon direction="down" />
        </ScrollButton>
      </SectionEcrite>
    </SectionStyle>
  );
}

export default MemoireComponent;
