import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { menuSelected } from '../reduxhandler/menuredux/menuSlice';
import EcritComponent from '../components/ecritsComponents/EcritComponent';

function RecherchePage() {
  const title = 'Écrits';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(menuSelected(title));
  }, [dispatch, title]);

  return (
    <div>
      <EcritComponent />
    </div>
  );
}

export default RecherchePage;
