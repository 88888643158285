import styled from 'styled-components';
import {
  diplome,
  experience,
  hardSkill,
  competence,
  softSkill,
  passion
} from './skillList';
import OngletSkill from './OngletSkill';
import OngletCompetence from './OngletCompetence';
import OngletDiplomes from './OngletDiplomes';
import OngletExperience from './OngletExperience';
import OngletPassion from './OngletPassion';
import { variablesGlobals } from '../../style/variables';
import { motion } from 'framer-motion';

const ContainerAboutStyle = styled.div`
  position: relative;
  font-family: ${variablesGlobals.fontFamilyWriting};
  color: ${variablesGlobals.darkGreyBack};
  width: 100%;
  padding: 250px 150px;
  box-sizing: border-box;
  top: -50px;
  left: 0%;
  height: min-content;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 100;
  overflow: hidden;
  overflow-x: hidden;
  user-select: none;
  @media (min-width: 200px) and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    left: 0;
    top: 150px;
    padding: 50px 10px;
  }
  h2 {
    padding: 0 10px;
    background-color: #ffffff;
    border-radius: 15px;
    text-align: center;
    border: none;
    color: ${variablesGlobals.darkGreyBack};
    font-size: larger;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: medium;
      border: none;
    }
  }
`;

const ColonneOne = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 30%;

  @media (min-width: 200px) and (max-width: 1200px) {
    width: fit-content;
  }
  & > div:first-child {
    @media (min-width: 200px) and (max-width: 1200px) {
      width: 100%;
    }
  }
  & > div:nth-child(2) {
    top: 30px;
    height: fit-content;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;

      @media (min-width: 200px) and (max-width: 1200px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  & > div:nth-child(3) {
    display: flex;
    width: fit-content;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 200px) and (max-width: 1200px) {
      width: 300px;
    }
  }
`;
const ColonneTwo = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  padding: 80px;
  position: relative;
  align-items: start;
  justify-content: center;
  width: fit-content;
  top: 100px;

  @media (min-width: 200px) and (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
    top: 0px;
  }
  @media (min-width: 1201px) and (max-width: 1700px) {
    grid-template-columns: repeat(2, 1fr);
    top: 600px;
  }
  & > div:nth-child(odd) {
    position: relative;
    left: 20px;
    top: 40px;
  }
  & > div:nth-child(even) {
  }
`;

const ColonneTree = styled(motion.div)`
  display: flex;
  flex-direction: column;
  @media (min-width: 200px) and (max-width: 1200px) {
    width: fit-content;
  }
  & > div:last-child {
    height: 500px;
  }
`;
// Variants pour gérer l'animation en cascade
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4 // Délai entre chaque enfant
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 60 },
  visible: { opacity: 1, y: 0 }
};

function ContainerAbout() {
  return (
    <ContainerAboutStyle>
      <ColonneOne
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={itemVariants}>
          <h2>DIPLOMES</h2>
          {diplome.map((element, i) => (
            <OngletDiplomes
              key={`diplome_${i}`}
              diplomePart={element}
            ></OngletDiplomes>
          ))}
        </motion.div>

        <motion.div variants={itemVariants}>
          <h2>SOFT SKILLS</h2>
          <div>
            {softSkill.map((element, i) => (
              <div key={`hard-skill_${i}`}>
                <OngletSkill
                  key={`hardSkill-skill_${i}`}
                  skill={element}
                />
              </div>
            ))}
          </div>
        </motion.div>
        <motion.div
          style={{ width: '100%' }}
          variants={itemVariants}
        >
          <h2>PASSIONS</h2>
          <div>
            {passion.map((element, i) => (
              <div key={`passion_${i}`}>
                <OngletPassion
                  key={`passionOnglet_${i}`}
                  passion={element}
                />
              </div>
            ))}
          </div>
        </motion.div>
      </ColonneOne>
      <ColonneTwo
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <h2>MAITRISE</h2>
        {hardSkill.map((element, i) => (
          <motion.div
            variants={itemVariants}
            key={`hard-skill_${i}`}
          >
            <OngletSkill skill={element} />
          </motion.div>
        ))}
      </ColonneTwo>
      <ColonneTree
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div variants={itemVariants}>
          <h2>COMPETENCES</h2>
          {competence.map((element, i) => (
            <OngletCompetence
              key={`competence_${i}`}
              competence={element}
            ></OngletCompetence>
          ))}
        </motion.div>
        <motion.div variants={itemVariants}>
          <h2>EXPERIENCES</h2>
          {experience.map((element, i) => (
            <OngletExperience
              key={`experience_${i}`}
              experience={element}
            ></OngletExperience>
          ))}
        </motion.div>
      </ColonneTree>
    </ContainerAboutStyle>
  );
}

export default ContainerAbout;
