import React, {
  useState,
  useRef,
  useEffect
} from 'react';
import styled, { css } from 'styled-components';
import {
  motion,
  AnimatePresence
} from 'framer-motion';
import { variablesGlobals } from '../../style/variables';

const Wrappers = styled.div`
  position: fixed;
  right: 0px;
  top: 0px;
  height: fit-content;
  margin: 0;
  overflow-x: hidden;
  user-select: none;
  white-space: pre-line;
  z-index: 3000;
  user-select: none;

  @media (min-width: 200px) and (max-width: 1200px) {
    top: 160px;
  }
`;

const HamburgerButton = styled.button`
  position: fixed;
  top: 0px;
  right: 10px;
  background-color: ${variablesGlobals.veryDarkGreyBack};
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  user-select: none;

  font-size: 1.5rem;
  box-shadow: 1px 5px 5px black;
  cursor: pointer;
  z-index: 2000;
  @media (min-width: 200px) and (max-width: 1200px) {
    top: 150px;
  }
`;

const ButtonContainer = styled(motion.div)`
  white-space: pre-line;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  user-select: none;
  width: fit-content;
  margin-top: -5px; /* Space below the hamburger button */
  @media (min-width: 200px) and (max-width: 1200px) {
    background-color: ${variablesGlobals.transparentWhite};
    border-radius: 15px;
  }
`;

const singleDigitStyle = css`
  color: #ffffff;
  background-color: ${variablesGlobals.veryDarkGreyBack};
  font-weight: bold;
  font-size: 0.8em;
  text-align: right;
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.5em;
  }
`;

const multiDigitStyle = css`
  background-color: ${variablesGlobals.veryLightGreyBack};
  color: #000000;
  font-size: 0.8em;
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.5em;
  }
`;

const ChapterButton = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 0.9em;
  user-select: none;

  border-radius: 25px;
  margin: 5px 0;
  padding: 5px;
  white-space: pre-line;
  cursor: pointer;
  width: fit-content;
  font-family: ${variablesGlobals.fontFamilyLogo};
  transition: filter 0.2s ease-in-out,
    font-size 0.2s ease-in-out;
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.6em;
    
  }
  ${({ isSingleDigit }) =>
    isSingleDigit
      ? singleDigitStyle
      : multiDigitStyle}

  &:hover {
    filter: brightness(1.5);
    font-size: 1em;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.7em;
    }

  }

  &.active {
    color: ${variablesGlobals.veryDarkGreyBack};
    border: 2px solid
      ${variablesGlobals.veryDarkGreyBack};
  }
`;

const AnimatedSubChapterDetails = styled(
  motion.div
)`
  border-left: 3px solid
    ${variablesGlobals.darkGreyBack};
  padding: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

function MenuEcrit({ chapters, onChapterClick }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [
    activeChapterIndex,
    setActiveChapterIndex
  ] = useState(null);
  const menuRef = useRef(null);

  const isSingleDigit = (chapter) =>
    typeof chapter.chapter === 'string' &&
    /^-?\d$/.test(chapter.chapter);

  const toggleChapter = (index) => {
    setActiveChapterIndex(
      activeChapterIndex === index ? null : index
    );
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
      setActiveChapterIndex(null);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutside
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutside
      );
    };
  }, []);

  return (
    <>
      <HamburgerButton
        onClick={() =>
          setMenuOpen((prev) => !prev)
        }
      >
        ☰
      </HamburgerButton>
      <AnimatePresence>
        {menuOpen && (
          <Wrappers ref={menuRef}>
            <ButtonContainer
              initial={{ height: 0, opacity: 0 }}
              animate={{
                height: 'auto',
                opacity: 1
              }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              {chapters.map((chapter, index) => {
                const isMainChapter =
                  isSingleDigit(chapter);

                return (
                  <div key={index}>
                    {isMainChapter && (
                      <ChapterButton
                        onClick={() => {
                          onChapterClick(
                            chapter.order - 1
                          );
                          toggleChapter(index);
                        }}
                        isSingleDigit={
                          isMainChapter
                        }
                        className={
                          chapter.isActive
                            ? 'active'
                            : ''
                        }
                      >
                        {chapter.chapter} -{' '}
                        {chapter.title}
                      </ChapterButton>
                    )}
                    <AnimatePresence>
                      {activeChapterIndex ===
                        index && (
                        <AnimatedSubChapterDetails
                          initial={{
                            height: 0,
                            opacity: 0
                          }}
                          animate={{
                            height: 'auto',
                            opacity: 1
                          }}
                          exit={{
                            height: 0,
                            opacity: 0
                          }}
                          transition={{
                            duration: 0.3
                          }}
                        >
                          {chapters
                            .filter(
                              (sub) =>
                                typeof sub.chapter ===
                                  'string' &&
                                sub.chapter.startsWith(
                                  chapter.chapter
                                ) &&
                                sub.chapter !==
                                  chapter.chapter
                            )
                            .map(
                              (
                                subChapter,
                                subIndex
                              ) => (
                                <ChapterButton
                                  key={subIndex}
                                  onClick={() =>
                                    onChapterClick(
                                      subChapter.order -
                                        1
                                    )
                                  }
                                >
                                  {
                                    subChapter.chapter
                                  }{' '}
                                  -{' '}
                                  {
                                    subChapter.title
                                  }
                                </ChapterButton>
                              )
                            )}
                        </AnimatedSubChapterDetails>
                      )}
                    </AnimatePresence>
                  </div>
                );
              })}
            </ButtonContainer>
          </Wrappers>
        )}
      </AnimatePresence>
    </>
  );
}

export default MenuEcrit;
