import {
  useDispatch,
  useSelector
} from 'react-redux';
import { useEffect } from 'react';
import ModalDisplayImages from '../components/artcomponents/ModalDisplayImage';
import { menuSelected } from '../reduxhandler/menuredux/menuSlice';
function VisuelPage() {
  const title = 'Galerie';
  const dispatch =
    useDispatch();
  const dataSelected =
    useSelector(
      (state) =>
        state.modalReducer
          .dataSelected
    );

  useEffect(() => {
    dispatch(
      menuSelected(title)
    );
  }, [dispatch, title]);

  return (
    <>
      {' '}
      {dataSelected && (
        <ModalDisplayImages />
      )}
    </>
  );
}

export default VisuelPage;
