import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';

const SidebarContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 20%;
  column-gap: 20px;
  width: 72%;
  height: fit-content;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 0px;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
  height: fit-content;
  flex-direction: row;
  z-index: 2000;
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 150px;
    top: 18px;
    padding: 0 5px;
    left: 60%;
  }
`;

const ArticleLink = styled(Link)`
  margin-bottom: 10px;
  border-radius: 15px;
  text-decoration: none;
  box-sizing: border-box;
  padding: 10px 20px;
  color: ${variablesGlobals.darkGreyBack};
  font-size: 1em;
  font-weight: bold;
  min-width: 150px;
  min-height: 50px;
  border-bottom: 1px solid
    ${variablesGlobals.veryLightGreyBack};
  background-color: white;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: ${variablesGlobals.veryLightGreyBack};
  }

  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.6em;
    padding: 2px;
  }
`;

const ArticleDate = styled.small`
  font-size: 0.8em;
  color: ${variablesGlobals.veryDarkGreyBack};
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  max-width: 100vw;
  align-items: center;
  @media (min-width: 200px) and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    align-items: center;
    gap: 1px;
    height: 260px;
    overflow: hidden;
    margin: 0px;
  }
`;

const NavButtonLeft = styled.button`
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  top: 7px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 20px;
    margin: 20px 0px;
    height: 20px;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 25px 20px 0; /* Triangle pointant à gauche */
    border-color: transparent
      ${variablesGlobals.veryDarkGreyBack}
      transparent transparent;
    transition: border-color 0.3s ease;
  }

  &:hover::before {
    border-color: transparent
      ${variablesGlobals.darkGreyBack} transparent
      transparent;
  }
`;

const NavButtonRight = styled(NavButtonLeft)`
  &::before {
    border-width: 20px 0 20px 25px; /* Triangle pointant à droite */
    border-color: transparent transparent
      transparent
      ${variablesGlobals.veryDarkGreyBack};
  }

  &:hover::before {
    border-color: transparent transparent
      transparent ${variablesGlobals.darkGreyBack};
  }
`;

function ArticleSidebar() {
  const { listArticle } = useSelector(
    (state) => state.articleReducer
  );

  // Pagination state
  const [currentPage, setCurrentPage] =
    useState(1);
  const articlesPerPage = 3;

  // Calculate indices for current articles
  const startIndex =
    (currentPage - 1) * articlesPerPage;
  const endIndex = startIndex + articlesPerPage;
  const currentArticles = listArticle.slice(
    startIndex,
    endIndex
  );

  // Calculate total pages
  const totalPages = Math.ceil(
    listArticle.length / articlesPerPage
  );

  // Handlers for pagination
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <SidebarContainer>
      <NavigationContainer>
        <NavButtonLeft
          key="prev"
          onClick={handlePrev}
          disabled={currentPage === 1}
        ></NavButtonLeft>
        {currentArticles.map((article) => (
          <ArticleLink
            key={'article-' + article.id}
            to={`/article/${article.title
              .toLowerCase()
              .replace(/\s+/g, '-')}`}
          >
            {article.title}
            <br />
            <ArticleDate>
              {new Date(
                article.date
              ).toLocaleDateString('fr-FR')}
            </ArticleDate>
          </ArticleLink>
        ))}

        <NavButtonRight
          key="next"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        ></NavButtonRight>
      </NavigationContainer>
    </SidebarContainer>
  );
}

export default ArticleSidebar;
