import { motion } from "framer-motion";
import styled from "styled-components";
import { variablesGlobals } from "../../style/variables";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const ContactStyle = styled(motion.div)`
   height: fit-content;
   width: 100%;
   display: flex;
   max-width:100vw;
   padding-bottom: 300px;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   top: 200px;
   overflow-y: hidden;
   position: absolute;

   @media (min-width: 200px) and (max-width: 1200px) {
    top:180px;
}
`;

const SpanMark = styled.span`
    font-weight: 700;
    font-size: larger;
`;

const TexteStyle = styled(motion.div)`
    height: fit-content;
    width: 70%;
    right: 0;
    bottom: 0;
    font-size: normal;
    margin: 5px auto;
    border-radius: 25px;
    padding: 15px;
    box-sizing: border-box;
    background-color: ${variablesGlobals.opacityWhite};
    color: ${variablesGlobals.darkGreyBack};
    font-family: ${variablesGlobals.fontFamilyWriting};
    position: relative;
    a{
        text-decoration: none;
        color: ${variablesGlobals.darkGreyBack};
    }
    h1 {
        font-size: 1.8em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: 200px) and (max-width: 1200px) {
        width: 100%;
        font-size: small;
    }
`;



const TexteStyleAside = styled(motion.div)`
    height: fit-content;
    width: 70%;
    right: 0;
    bottom: 0;
    font-size: normal;
    margin: 5px auto;
    border-radius: 25px;
    padding: 15px;
    box-sizing: border-box;
    background-color:white;
    color: ${variablesGlobals.darkGreyBack};
    font-family: ${variablesGlobals.fontFamilyWriting};
    position: relative;
    a{
        text-decoration: none;
        color: ${variablesGlobals.darkGreyBack};
    }
    h1 {
        font-size: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: 200px) and (max-width: 1200px) {
        width: 100%;
        font-size: small;
    }
`;



const AsideText = styled(motion.div)`
position:fixed;
right:0;
bottom:0;
font-size: smaller;
width:350px;

@media (min-width: 200px) and (max-width: 1200px) {
    width:200px;
    font-size: smaller;
}
`;


// Variants pour gérer l'animation en cascade
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4, // Délai entre chaque enfant
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 60 },
  visible: { opacity: 1, y: 0 },
};

function Contact() {
    return (<>
      <ContactStyle
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <TexteStyle variants={itemVariants}>
          <h1>Contactez-moi</h1>
        </TexteStyle>
        <TexteStyle variants={itemVariants}>
          <p>Ton projet doit voir le jour !</p>
          <p>Vous avez une aventure numérique sur laquelle je peux vous accompagner?
            
          Je serais ravie que vous me contactiez! Mon profil pluri-disciplinaire encadre toutes les facettes que requiert la réussite d'une application.
          </p>
        </TexteStyle>

        <TexteStyle variants={itemVariants}>
          <p>
            En art, se trouve tout un processus d’encodage et de décodage : des émotions et des concepts traduits en formes visuelles, sonores, littéraires. Nous les interprétons, les décodons en fonction de notre propre sensibilité, nos expériences.
          </p>
          <p>
            Analogue à l’encodage et au décodage en science de l'informatique, là où les données sont structurées pour être véhiculées et transmises.
            La tentative de transposer et de transmettre des éléments intègres à travers toutes sortes de systèmes et de symboles, qu'ils soient artistiques ou numériques, se trouve partout.
          </p>
        </TexteStyle>

        <TexteStyle variants={itemVariants}>
          <p>L'art d'encoder le monde avec soi pour le décoder ensemble. Voici mon étude.</p>
        </TexteStyle>

      </ContactStyle>
      <AsideText variants={containerVariants}
        initial="hidden"
        animate="visible">
      <TexteStyleAside variants={itemVariants}>
          <div><FontAwesomeIcon icon={faEnvelope} /> Voici mon mail : <a href="mailto:yolkin.cocud@gmail.com">yolkin.cocud@gmail.com</a></div>
        </TexteStyleAside>

        <TexteStyleAside variants={itemVariants}>
          <div><FontAwesomeIcon icon={faLinkedin} /> Voici mon LinkedIn : <a href="https://www.linkedin.com/in/yolkin-cocud" target="_blank">LinkedIn.com/in/yolkin-cocud</a></div>
        </TexteStyleAside>
      
        </AsideText>
      
      </>
    );
}

export default Contact;
