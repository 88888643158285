import {
  configureStore,
  combineReducers,
  getDefaultMiddleware
} from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Utilisation du localStorage pour persister les données
import modalReducer from '../reduxhandler/modalredux/modalSlice';
import tagsReducer from '../reduxhandler/tagsredux/tagsSlice';
import menuReducer from '../reduxhandler/menuredux/menuSlice';
import object3dReducer from '../reduxhandler/object3dredux/object3dSlice';
import paginationReducer from '../reduxhandler/object3dredux/paginationSlice';
import ecritReducer from '../reduxhandler/ecritredux/ecritSlice';
import articleReducer from '../reduxhandler/articleredux/articleSlice';
// Configuration pour redux-persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [ecritReducer] // Ajoute les reducers que tu veux persister ici
};

// Combinaison des reducers
const rootReducer = combineReducers({
  menuReducer,
  modalReducer,
  object3dReducer,
  paginationReducer,
  tagsReducer,
  ecritReducer,
  articleReducer
});

// Applique persistReducer pour rendre certains reducers persistants
const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
);

// Configuration du store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore les actions spécifiques de redux-persist
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE'
        ]
      }
    })
});

// Crée le persistor pour gérer la persistance
export const persistor = persistStore(store);

export default store;
