import {
  useLocation,
  useNavigate
} from 'react-router-dom';
import styled from 'styled-components';
import { variablesGlobals } from '../style/variables';

// Style du bouton
const BackButton = styled.button`
  position: fixed;
  top: 0px;
  left: 100px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10000;

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 25px 20px 0; /* Triangle pointant à gauche */
    border-color: transparent
      ${variablesGlobals.darkGreyBack} transparent
      transparent;
    transition: border-color 0.3s ease;
  }

  &:hover::before {
    border-color: transparent
      ${variablesGlobals.lightGreyBack}
      transparent transparent;
  }
`;

function BackButtonWrapper({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  // Vérifiez si on est sur la première page
  const isFirstPage = location.pathname === '/';

  return (
    <>
      {/* Affichez le bouton uniquement si on n'est pas sur la première page */}
      {!isFirstPage && (
        <BackButton
          onClick={() => navigate(-1)}
        />
      )}
      {children}
    </>
  );
}

export default BackButtonWrapper;
