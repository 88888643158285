import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import { motion } from 'framer-motion';

const ParcoursStyle = styled(motion.div)`
  height: fit-content;
  width: 100%;
  display: flex;
  padding-bottom: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 200px;
  max-width:100vw;

  overflow-y: hidden;
  position: absolute;
  @media (min-width: 200px) and (max-width: 1200px) {
    top: 180px;
  }
`;

const SpanMark = styled.span`
  font-weight: 700;
  font-size: larger;
`;

const TexteStyle = styled(motion.div)`
  height: fit-content;
  width: 70%;
  right: 0;
  bottom: 0;
  font-size: normal;
  margin: 5px auto;
  border-radius: 25px;
  padding: 15px;
  box-sizing: border-box;
  background-color: ${variablesGlobals.opacityWhite};
  color: ${variablesGlobals.darkGreyBack};
  font-family: ${variablesGlobals.fontFamilyWriting};
  position: relative;
  h1 {
    font-size: 1.8em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 100%;
    font-size: small;
  }
`;

// Variants pour gérer l'animation en cascade
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4 // Délai entre chaque enfant
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 60 },
  visible: { opacity: 1, y: 0 }
};

function Parcours() {
  return (
    <ParcoursStyle
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <TexteStyle variants={itemVariants}>
        <h1>L'harmonie entre Art et Code</h1>
      </TexteStyle>
      <TexteStyle variants={itemVariants}>
        <p>
          En 2008, je vis dans le monde de l'art.
          Chaque coup de pinceau, chaque note de
          musique, chaque fragment de vidéo et
          chaque ligne de code devient une
          extension de mon énergie. Entre patience
          et audace, je façonne des œuvres
          uniques, l’art s’entrelace avec la
          technologie, je suis toujours à la
          recherche d’une nouvelle dimension
          créative.
        </p>
      </TexteStyle>
      <TexteStyle variants={itemVariants}>
        <p>
          En 2015, j'entreprends une quête
          nouvelle : celle d'étudier l'histoire et
          la structure derrière la beauté.
          J'intègre une licence en Arts, Design
          Graphique et Interaction, où les projets
          deviennent une danse entre conception
          artistique et innovation technique. En
          2020, cette dualité prend une forme plus
          large avec un Master en Arts et
          Management, le moment où je cofonde
          l'association Sytrileen, un espace en
          faveur des artistes où je dirige des
          projets numériques en tant que lead
          developper et designer.
        </p>
      </TexteStyle>
      <TexteStyle variants={itemVariants}>
        <p>
          En 2022, le numérique devient le cœur
          battant de mon univers. J’obtiens un
          Master en Humanités Numériques, où les
          techniques tel que l'UX Design, les
          bases de données, les algorithmes
          deviennent plus que des outils, elles
          participent au façonnement d'expériences
          humaines profondes. Chaque ligne de code
          est une invitation à explorer de
          nouveaux mondes, à anticiper les besoins
          en fonctionnalités.
        </p>
      </TexteStyle>
      <TexteStyle variants={itemVariants}>
        <p>
          2024 marque un tournant décisif, celui
          de la maîtrise technique. En obtenant
          mon titre de Concepteur Développeur de
          Logiciels, j’explore des architectures
          robustes avec React, Next.js que je
          déploie avec un regard DevOps, en
          cherchant toujours à faire évoluer mes
          créations avec le temps, à l’image d’une
          œuvre vivante.
        </p>
      </TexteStyle>
      <TexteStyle variants={itemVariants}>
        <p>
          Aujourd'hui, mes compétences en
          front-end et back-end (Python, APIs
          REST) s’unissent à un nouveau chapitre :
          celui de l’intelligence artificielle.
          Grâce à mon titre en Datascience et
          Machine Learning, je crée des systèmes
          intelligents, des chatbots, des modèles
          de reconnaissance visuelle.
        </p>
      </TexteStyle>
      <TexteStyle variants={itemVariants}>
        <p>
          Il s'agit d'un parcours non seulement
          technique mais aussi marqué par des
          valeurs profondes : la créativité, la
          curiosité, l'esprit d'équipe et le
          partage. Aujourd’hui, mes compêtences
          fusionnent plusieurs disciplines: dont
          celui de la création artistique et de
          l'informatique, concrétisant des projets
          innovants en tant qu’AutoEntrepreneur.
        </p>
      </TexteStyle>
      <TexteStyle variants={itemVariants}>
        <p>
          Avec des outils modernes tels que React,
          Python, Docker et les services cloud, je
          fais croitre des interfaces, des
          back-ends solides, et des systèmes
          intelligents. Ma passion pour la musique
          et l’ingénierie continue d’alimenter mes
          intuitions, pour que chaque projet porte
          en lui-même, la touche personnelle et la
          beauté à chaque ligne de code.
        </p>
      </TexteStyle>
    </ParcoursStyle>
  );
}

export default Parcours;
