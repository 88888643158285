import { useEffect, useState } from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import OrderArrays from '../orderarrays/OrderArrays';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faXmark
} from '@fortawesome/free-solid-svg-icons';
import TagsSection from './tags/TagsSection';
import SelectedTagsSection from './tags/TagsSelected';
import { filterByWordkey } from '../../reduxhandler/object3dredux/object3dSlice';

const SearchWrapper = styled.div`
  position: absolute;
  background-color: #ffffff;
  border-radius: 0px 0px 0px 50px;
  top: 10px;
  min-width: 200px;
  right: 0;
  padding-left: 100px;
  max-width: 100vw;
  padding-bottom: 5px;
  height: fit-content;
  width: fit-content;
  justify-content: flex-end;
  white-space: normal;
  z-index: 2;
  display: flex;
  flex-direction: row;
  color: ${variablesGlobals.darkGreyBack};
  align-items: center;
  align-content: center;

  @media (min-width: 200px) and (max-width: 1200px) {
    flex-direction: column;
    top: 0px;
    width: fit-content;
    padding-left: 30px;
    padding-bottom: 10px;
    font-size: smaller;
    padding-top: 0px;
    clip-path: none;
    border-radius: 0 0 0 15px;
  }

  && input {
    width: 100px;
    height: 30px;
    border: 2px solid
      ${variablesGlobals.lightGreyBack};
    font-family: ${variablesGlobals.fontFamilyWriting};
    background-color: white;
    outline: ${variablesGlobals.lightGreyBack};
    margin: 0px 10px;
    padding: 0 10px;
    color: ${variablesGlobals.darkGreyBack};
    font-size: 1em;
    border-radius: 5px;
    @media (min-width: 200px) and (max-width: 1200px) {
      width: 100px;
      height: 20px;
      margin: 0 10px;
    }
  }
`;
const ButtonSubmit = styled.button`
  border-radius: 20px;
  border: none;
  margin: auto 10px;
  color: ${variablesGlobals.darkGreyBack};

  font-size: 1.5em;
  cursor: pointer;
  @media (min-width: 200px) and (max-width: 1200px) {
    margin-left: 0;
    opacity: 1;
    font-size: 1em;
  }
  &:hover {
    background-color: white;
  }
`;

const FontAwesomeStyle = styled(FontAwesomeIcon)`
  color: ${variablesGlobals.darkGreyBack};
  background-color: transparent;
  width: 20px;
  border: none;
  margin: auto 2px;
  font-size: 1.4em;
  cursor: pointer;
  &:hover {
    background-color: white;
    color: ${variablesGlobals.lightGreyBack};
    transition: 0.5s;
  }
`;
const ContainSearch = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: center;
  margin: 0;
  top: 3px;
  position: relative;
`;

const ContainTagResultat = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  align-items: center;
  padding: 0;
  & > p {
    font-size: 0.6em;
    margin: 0;
    padding: 0;
    text-align: center;
    height: 15px;
    width: 100px;
    font-size: 1em;
    white-space: nowrap;
  }
`;
function Search() {
  const [input, setInput] = useState('');
  const [tagsSelected, setTagsSelected] =
    useState([]);

  const dispatch = useDispatch();
  const dataList =
    useSelector(
      (state) =>
        state.object3dReducer.filteredList
    ) || [];

  const search = () => {
    const validKeywords = input
      .split(' ')
      .map((word) =>
        word.replace(/[^\p{L}\p{N}\p{M}]/gu, '')
      ) // Remplace tout caractère non-autorisé
      .filter((word) => word && word?.length > 0);
    const searchPayload = {
      keywords: validKeywords,
      selectedTags: tagsSelected
    };

    dispatch(filterByWordkey(searchPayload));
  };

  const getTags = (tag) => {
    setTagsSelected((prevTags) => {
      if (prevTags.includes(tag)) {
        return prevTags.filter((t) => t !== tag);
      } else {
        return [...prevTags, tag];
      }
    });
  };

  const deleteTag = (tag) => {
    setTagsSelected((prevTags) => {
      return prevTags.filter((t) => t !== tag);
    });
  };

  const clearInput = () => {
    setInput('');
    setTagsSelected([]);
  };

  useEffect(() => {
    search();
  }, [tagsSelected]);

  const preventPropagationSearch = (e) => {
    e.stopPropagation();
  };
  return (
    <>
      <SearchWrapper
        onClick={preventPropagationSearch}
      >
        <ContainTagResultat>
          <SelectedTagsSection
            tags={tagsSelected}
            deleteTag={deleteTag}
          />
          <p>{dataList?.length} resultats </p>
        </ContainTagResultat>
        <ContainSearch>
          <input
            type="text"
            value={input}
            onChange={(e) =>
              setInput(e.target.value)
            }
          />
          <FontAwesomeStyle
            icon={faXmark}
            onMouseDown={() => clearInput()}
            onMouseUp={() => search}
          />
          <ButtonSubmit onClick={search}>
            <FontAwesomeStyle icon={faSearch} />
          </ButtonSubmit>
        </ContainSearch>
        <OrderArrays />
      </SearchWrapper>

      <TagsSection wordKey={getTags} />
    </>
  );
}

export default Search;
