import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import { Link } from 'react-router-dom';
import CollapseMenu from './CollapseMenu';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Search from '../recherchecomponents/Search';
import ProfilLeen from '../../assets/profil_leen.svg';

const MainContainer = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
`;
const LogoStyle = styled(Link)`
  top: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  position: relative;
  width: fit-content;
  margin: 10px 5px;
  color: ${variablesGlobals.darkGreyBack};
  font-family: ${variablesGlobals.fontFamilyLogo};
  font-size: 1.2em;
  transition: 0.5s;
  &:hover {
    filter: brightness(2);
    transition: 0.5s;
  }
  z-index: 10000;
  text-decoration: none;
  @media (min-width: 200px) and (max-width: 1200px) {
    left: 0;
    font-size: 1em;
  }

  & img {
    position: relative;
    left: -60px;
    top: -10px;
    margin-bottom: 0px;
  }
`;
const LogoLinkLeenWrapp = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const LogoLink = styled.span`
  font-size: 0.5em;
  border: none;
  font-weight: 400;
`;
const LogoLeen = styled.span`
  margin: 0;
  font-size: 2em;
  font-weight: 400;
  line-height: 0.95em;
`;

const LogoCom = styled.span`
  font-size: 0.4em;
  font-weight: 400;
  line-height: 2.3em;
`;

function Navigation() {
  const location = useLocation();
  return (
    <MainContainer>
      <LogoStyle to="/">
        <LogoLinkLeenWrapp>
          <LogoLink>Link </LogoLink>
          <LogoLeen>Leen</LogoLeen>
        </LogoLinkLeenWrapp>
        <LogoCom>.com</LogoCom>
        <img
          src={ProfilLeen}
          alt="Profil Leen"
          width="50px"
        />
      </LogoStyle>
      <CollapseMenu />
      {location.pathname.includes('/galerie') && (
        <></>
      )}
      {(location.pathname.includes(
        '/galerie/visuel'
      ) ||
        location.pathname.includes(
          '/galerie'
        )) && <Search />}
    </MainContainer>
  );
}

export default Navigation;
