import React, {
  useState,
  useEffect
} from 'react';
import MemoireComponent from './MemoireComponent';
import styled, { css } from 'styled-components';
import {
  getDatasJePense,
  getDatasInspiration
} from '../../services/getDatas';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { variablesGlobals } from '../../style/variables';

// Styles}
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  max-width: 100vw;

  position: absolute;
  top: ${({ $activeMemoire }) =>
    $activeMemoire ? '0px' : '40px'};
  width: 100%;
  z-index: 1000;

  @media (min-width: 200px) and (max-width: 1200px) {
    flex-direction: column;
    gap: 20px;
    display: ${({ $activeMemoire }) =>
      $activeMemoire ? 'flex' : 'column'};
    align-items: center;
    top: ${({ $activeMemoire }) =>
      $activeMemoire ? '10px' : '200px'};
  }
`;

const Button = styled.button`
  width: ${({ $active }) =>
    $active ? '100px' : '300px'};
  height: ${({ $active }) =>
    $active ? '60px' : '200px'};
  background-image: ${({ $background }) =>
    `url(${$background})`};
  background-size: cover;
  background-position: center;
  color: white;
  font-size: ${({ $active }) =>
    $active ? '0.7em' : '0.8em'};
  font-weight: bold;
  text-shadow: 1px 1px 15px black;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: ${variablesGlobals.fontFamilyLogo};
  &:hover {
    width: ${({ $active }) =>
      $active ? '100px' : '400px'};
  }

  ${({ $active }) =>
    !$active &&
    css`
      &:hover {
        transform: scale(1.05);
        box-shadow: 0px 4px 10px
          rgba(0, 0, 0, 0.3);
      }
    `}

  @media (min-width: 200px) and (max-width: 1200px) {
    width: ${({ $active }) =>
      $active ? '100px' : '150px'};
    height: ${({ $active }) =>
      $active ? '60px' : '150px'};
  }
`;

function EcritComponent() {
  const dispatch = useDispatch();

  const MEMOIRES = [
    {
      type: 'jePense',
      label:
        'Je pense, donc je crée : de la complétude pensive dans un art total',
      background:
        '/images/ecrits/je_pense/mirage.jpg',
      dataLoader: getDatasJePense
    },
    {
      type: 'inspiration',
      label:
        'La Muse à l’ère du numérique : Une approche du XXIe siècle de l’inspiration au sein de la création',
      background:
        '/images/ecrits/inspiration/la_muse.jpg',
      dataLoader: getDatasInspiration
    }
  ];

  const [activeMemoire, setActiveMemoire] =
    useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const dataState = useSelector(
    (state) => state.ecritReducer.pages
  );

  useEffect(() => {
    if (
      activeMemoire &&
      !dataState[activeMemoire]?.data
    ) {
      const memoireConfig = MEMOIRES.find(
        (memoire) =>
          memoire.type === activeMemoire
      );
      if (memoireConfig) {
        memoireConfig.dataLoader(dispatch);
      }
    }
  }, [activeMemoire, dispatch, dataState]);

  const handleClick = (type) => {
    if (activeMemoire === type) {
      setActiveMemoire(null);
      setIsLoaded(false);
    } else {
      setActiveMemoire(type);
      setIsLoaded(true);
    }
  };

  return (
    <>
      <ButtonContainer
        $activeMemoire={!!activeMemoire}
      >
        {MEMOIRES.map((memoire) =>
          activeMemoire &&
          activeMemoire !==
            memoire.type ? null : (
            <Button
              key={memoire.type}
              onClick={() =>
                handleClick(memoire.type)
              }
              $background={memoire.background}
              $active={!!activeMemoire}
            >
              {activeMemoire === memoire.type
                ? 'Fermer X'
                : `${memoire.label}`}
            </Button>
          )
        )}
      </ButtonContainer>
      {activeMemoire && isLoaded && (
        <MemoireComponent type={activeMemoire} />
      )}
    </>
  );
}

export default EcritComponent;
