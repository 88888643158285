export const variablesGlobals = {
  fontFamilyLogo:
    'Baskerville Old Face, Georgia, serif',
  fontFamilyWriting: 'B612 Mono, monospace',
  darkGreyBack: '#8998a7',
  lightGreyBack: '#c0c8d3',
  veryLightGreyBack: '#e4e9f1',
  veryVeryLightGreyBack: '#f6f6f9',
  veryDarkGreyBack: '#29353d',
  opacityWhite: '#ffffffb1',
  transparentWhite: '#d4d8dde6'
};
