import TagsComponent from './TagsComponent';
import ArticleComponent from './ArticleComponent';
import styled from 'styled-components';
import CarnetPresentation from './CarnetPresentation';
import { variablesGlobals } from '../../style/variables';

const ArticlesContainer = styled.div`
  position: absolute;
  left: 50%;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  top: 0px;
  z-index: 3000;
  height: 800px;
  transform: translate(-50%, 0%);
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px;

  @media (min-width: 200px) and (max-width: 1200px) {
    height: 800px;
    padding: 0px;
  }
`;

function CarnetComponent() {
  return (
    <ArticlesContainer>
      <CarnetPresentation />
      <TagsComponent />
      <ArticleComponent />
    </ArticlesContainer>
  );
}

export default CarnetComponent;
