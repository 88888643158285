import { createSlice } from '@reduxjs/toolkit';
import { listTags } from '../../components/carnetcomponent/listTags';
import article from '../../data/articles.json';
const initialState = {
  listArticle: article,
  searchTags: [],
  searchKeyword: ''
};

const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    setSearchTags: (state, action) => {
      state.searchTags = action.payload;
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    }
  }
});

export const { setSearchTags, setSearchKeyword } =
  articleSlice.actions;

export default articleSlice.reducer;
