import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import { parseTextImage } from '../ecritsComponents/parseTextImage';
import CarnetPresentation from './CarnetPresentation';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { menuSelected } from '../../reduxhandler/menuredux/menuSlice';
import ArticleSidebar from './ArticleSidebar';
import { Helmet } from 'react-helmet-async';

const ArticleDetailContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translate(0%, -50%);
  width: 70%;
  margin: 50px auto;
  height: auto;
  max-height: 700px;
  padding: 20px;
  overflow-y: auto;
  border-radius: 15px;
  background-color: ${variablesGlobals.opacityWhite};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  color: ${variablesGlobals.veryDarkGreyBack};
  font-size: 0.8em;
  max-width: 100vw;
  @media (min-width: 1200px) and (max-width: 1400px) {
    top: 420px;
    width: 68%;
    height: 700px;
  }
  @media (min-width: 200px) and (max-width: 1200px) {
    top: 520px;
    width: 90%;
    max-width: 90vw;

    left: 0px;
    right: 0px;
    height: 520px;
    font-size: 0.6em;
  }
`;

const ArticleTitle = styled.h1`
  font-size: 2em;
  color: ${variablesGlobals.veryDarkGreyBack};
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.9em;
  }
`;

const ArticleDate = styled.small`
  display: block;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: ${variablesGlobals.veryDarkGreyBack};
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.6em;
  }
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  user-select: none;
  & div {
    background-color: ${variablesGlobals.darkGreyBack};
    color: white;
    border-radius: 30px;
    padding: 5px 10px;
    margin: 2px;
    font-size: 0.9em;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.6em;
    }
  }
`;

function ArticleDetail() {
  const titleMenu = 'Carnet';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(menuSelected(titleMenu));
  }, [dispatch, titleMenu]);

  const { title } = useParams();
  const { listArticle } = useSelector(
    (state) => state.articleReducer
  );

  const article = listArticle.find(
    (article) =>
      article.title
        .toLowerCase()
        .replace(/\s+/g, '-') ===
      title.toLowerCase()
  );

  if (!article) {
    return <p>Article non trouvé.</p>;
  }

  const url = '/images/';

  return (
    <>
      {/* Helmet pour la SEO dynamique */}
      <Helmet>
        <title>{article.title || ''}</title>
        <meta
          name="description"
          content={article.description || ''}
        />
        <meta
          property="og:title"
          content={article.title || ''}
        />
        <meta
          property="og:description"
          content={article.description || ''}
        />
        <meta
          property="og:image"
          content={article.images[0]?.path || ''}
        />
      </Helmet>
      <CarnetPresentation />
      <ArticleSidebar />
      <ArticleDetailContainer>
        <ArticleTitle>
          {article.title}
        </ArticleTitle>
        <ArticleDate>
          Publié le :{' '}
          {new Date(
            article.date
          ).toLocaleDateString('fr-FR')}
        </ArticleDate>
        <TagsContainer>
          {article.tags.map((tag) => (
            <div key={tag}>{tag}</div>
          ))}
        </TagsContainer>
        <div>
          {parseTextImage(
            article.description,
            article.images,
            article.notes,
            url
          )}
        </div>
        <div>
          {parseTextImage(
            article.text,
            article.images,
            article.notes,
            url
          )}
        </div>
      </ArticleDetailContainer>
    </>
  );
}

export default ArticleDetail;
