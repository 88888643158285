import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import square from '../../assets/icones/square-rss-solid.svg';
const StylePresentation = styled.div`
  width: 210px;
  max-height: 650px;
  overflow: hidden;
  height: fit-content;
  background-color: ${variablesGlobals.opacityWhite};
  position: absolute;
  left: 20px;
  top: 200px;
  color: ${variablesGlobals.darkGreyBack};
  font-family: ${variablesGlobals.fontFamilyLogo};
  padding: 10px;
  border-radius: 15px;
  & > div {
    margin: 10px 0px;
  }
  & img {
    width: 200px;
    height: auto;
    border-radius: 15px;
  }
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 100%;
    max-width: 200px;
    text-align: center;
    top: 160px;
    left: 0px;
    height: 100px;
    font-size: 0.5em;
    & > div {
      margin: 10px 0px;
    }

    & img {
      width: 50px;
      height: auto;
      border-radius: 15px;
    }
  }
`;

const PresentationContain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 200px) and (max-width: 1200px) {
    flex-direction: row;
  }
`;

function CarnetPresentation() {
  return (
    <StylePresentation>
      <PresentationContain>
        Découvrez ici mes écrits, activités
        artistiques et numériques, pensées et
        notes que je produits.
        <img
          src="/images/carnet/leen_portraits/leen_portrait_dos_musee_valenciennes.jpg"
          alt="moi, Leen copiant une peinture au musée"
        />
      </PresentationContain>{' '}
    </StylePresentation>
  );
}

export default CarnetPresentation;
