import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';

const ContainFoot = styled.div`
  position: fixed;
  bottom: 0px;
  width: fit-content;
  height: fit-content;
  color: ${variablesGlobals.darkGreyBack};
  font-size: 0.5em;
  padding: 0px 20px;
  z-index:20;
`;
function Footer() {
  return (
    <>
      <ContainFoot>
        <p>
          ©Linkleen, 2025. Tous droits réservés.
        </p>
      </ContainFoot>
    </>
  );
}

export default Footer;
