import React, {
  useEffect,
  useState
} from 'react';
import {
  titreTextRgpd,
  textRgpd
} from './textRgpd';
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';

const ContainerRgpd = styled.div`
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: #ffffffb2;
  border: 1px solid #ffffff;
  border-radius: 15px;
  max-width: 100vw;
  padding: 20px 30px;
  font-size: small;
  box-sizing: border-box;
  color: ${variablesGlobals.veryDarkGreyBack};
  width: 100%;
  text-align: center;
  z-index: 1000;
  text-align: left;
  height: fit-content;
  @media (min-width: 200px) and (max-width: 1200px) {
    height: 500px;
    font-size: 0.5em;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.644);
  z-index: 999;
`;

function Rgpd() {
  const [showModal, setShowModal] =
    useState(false);

  useEffect(() => {
    const isRgpdShown =
      sessionStorage.getItem('rgpdShown');
    if (!isRgpdShown) {
      setShowModal(true);
      sessionStorage.setItem('rgpdShown', 'true');
    }

    const timer = setTimeout(() => {
      setShowModal(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  if (!showModal) return null;

  return (
    <>
      <Overlay />
      <ContainerRgpd>
        <h3>{titreTextRgpd}</h3>
        <p>{textRgpd}</p>
      </ContainerRgpd>
    </>
  );
}

export default Rgpd;
