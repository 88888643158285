export const formatMenu = (menu) => {
    return menu.toLowerCase().replace(/['\s]/g, '_')
};

export const formatUrlMiniature = (data) => {
    const segments = data.image.split('/');
    segments.splice(2, 0, "miniatures");
    const pathWithMiniature = segments.join('/');
    const baseName = pathWithMiniature.split('.').slice(0, -1).join('.');
    const extension = pathWithMiniature.split('.').pop();

    return `/images${baseName}-min.${extension}`;
}

export function truncateText(text, length = 150) {
    if (!text) return 'Aucun texte disponible'; 
    return text.length > length ? text.substring(0, length) + '...' : text;
  }