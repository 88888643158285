import React, { useState } from 'react';
import styled from 'styled-components';

const ImageContainer = styled.figure`
  margin: 20px 0;
  text-align: center;
  position: relative;
  img {
    max-width: 80%;
    max-height: 400px;
    height: auto;
    border-radius: 8px;
    filter: ${({ $isBlurred }) =>
      $isBlurred ? 'blur(20px)' : 'none'};
    transition: filter 0.3s ease;
  }

  figcaption {
    font-size: 0.9em;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.5em;
    }
    color: #555;
    margin-top: 8px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: ${({ $isVisible }) =>
    $isVisible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
  font-size: 0.6em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  z-index: 1;
`;

const ImageRenderer = ({ image, url }) => {
  // Toujours initialiser l'état, même si `image` est undefined
  const [censure, setCensure] = useState(
    image?.censure === true
  );

  // Si `image` est invalide, on retourne un message ou rien
  if (!image || image.rang === undefined) {
    return <p>Image non disponible</p>;
  }

  return (
    <ImageContainer $isBlurred={censure}>
      {/* Overlay pour le texte "+18 Cliquez" */}
      <Overlay
        $isVisible={censure}
        onClick={() => setCensure(false)}
      >
        +18 - Cliquez pour voir l'image
      </Overlay>
      {/* Image */}
      <img
        src={url + image.path}
        alt={image.title || 'Image'}
      />
      {/* Légende */}
      {image.legend && (
        <figcaption>{image.legend}</figcaption>
      )}
    </ImageContainer>
  );
};

export default ImageRenderer;
