import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import GetSceneContext from './SceneContext.jsx';

function SceneRef({ children }) {
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(
    new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    )
  );
  const rendererRef = useRef(
    new THREE.WebGLRenderer({ antialias: true })
  );
  const containerRef = useRef(null);

  useEffect(() => {
    const renderer = rendererRef.current;
    const scene = sceneRef.current;
    const camera = cameraRef.current;
    const container = containerRef.current;

    const light = new THREE.AmbientLight(
      0xffffff,
      1
    );
    light.position.set(1, 2, 4);
    scene.add(light);

    // Initialize the renderer
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type =
      THREE.PCFSoftShadowMap;
    renderer.setSize(
      window.innerWidth,
      window.innerHeight
    );
    container.appendChild(renderer.domElement);

    // Setting the camera position
    camera.position.set(0, 0, 2);

    // Handle window resizing
    const handleResize = () => {
      camera.aspect =
        window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(
        window.innerWidth,
        window.innerHeight
      );
    };
    window.addEventListener(
      'resize',
      handleResize
    );

    // Mouse move event for controlling the camera (desktop only)
    const onMouseMove = (event) => {
      const xRotationFactor = 0.0005;
      const yRotationFactor = 0.0005;
      camera.rotation.x -=
        event.movementY * xRotationFactor;
      camera.rotation.y -=
        event.movementX * yRotationFactor;
    };
    document.addEventListener(
      'mousemove',
      onMouseMove
    );

    // Continuous rotation based on the mouse position at the edges of the screen (desktop only)
    let zone = null;
    let animationFrameId;

    const continuousRotation = () => {
      const rotationSpeed = 0.001;
      if (zone === 'top') {
        camera.rotation.x -= rotationSpeed;
      } else if (zone === 'bottom') {
        camera.rotation.x += rotationSpeed;
      } else if (zone === 'left') {
        camera.rotation.y -= rotationSpeed;
      } else if (zone === 'right') {
        camera.rotation.y += rotationSpeed;
      }
      animationFrameId = requestAnimationFrame(
        continuousRotation
      );
    };

    // Determine the 'zone' based on mouse position (desktop only)
    const onMouseMoveForZone = (event) => {
      const threshold = 50;
      if (event.clientY < threshold) {
        zone = 'bottom';
      } else if (
        event.clientY >
        window.innerHeight - threshold
      ) {
        zone = 'top';
      } else if (event.clientX < threshold) {
        zone = 'right';
      } else if (
        event.clientX >
        window.innerWidth - threshold
      ) {
        zone = 'left';
      } else {
        zone = null;
      }
    };
    document.addEventListener(
      'mousemove',
      onMouseMoveForZone
    );

    // Start the continuous rotation (desktop only)
    continuousRotation();

    // Mobile: Device orientation with smoothing
    let targetRotationX = 0;
    let targetRotationY = 0;
    const smoothFactor = 0.1; // Adjust this factor to control smoothing

    const onDeviceOrientation = (event) => {
      const { beta, gamma } = event;

      // Facteurs de sensibilité ajustés pour mobile
      const sensitivityFactorX = 1.5;
      const sensitivityFactorY = 3;

      // Calculer les cibles de rotation en fonction de beta et gamma
      targetRotationX =
        -THREE.MathUtils.degToRad(beta) *
        sensitivityFactorX;
      targetRotationY =
        -THREE.MathUtils.degToRad(gamma) *
        sensitivityFactorY;
    };

    // Check if the device is mobile
    const isMobile = window.matchMedia(
      '(max-width: 768px)'
    ).matches;

    if (isMobile) {
      // Add device orientation event listener for mobile
      if (window.DeviceOrientationEvent) {
        window.addEventListener(
          'deviceorientation',
          onDeviceOrientation
        );
      }
    }

    // Animation loop with smoothing
    const animate = () => {
      // Interpolate the camera rotation towards the target rotation
      if (isMobile) {
        camera.rotation.x +=
          (targetRotationX - camera.rotation.x) *
          smoothFactor;
        camera.rotation.y +=
          (targetRotationY - camera.rotation.y) *
          smoothFactor;
      }

      renderer.render(scene, camera);
      animationFrameId =
        requestAnimationFrame(animate);
    };
    animate();

    // Cleanup function
    return () => {
      cancelAnimationFrame(animationFrameId);
      renderer.dispose();
      window.removeEventListener(
        'resize',
        handleResize
      );
      if (
        isMobile &&
        window.DeviceOrientationEvent
      ) {
        window.removeEventListener(
          'deviceorientation',
          onDeviceOrientation
        );
      }
      document.removeEventListener(
        'mousemove',
        onMouseMove
      );
      document.removeEventListener(
        'mousemove',
        onMouseMoveForZone
      );
      if (container) {
        container.removeChild(
          renderer.domElement
        );
      }
    };
  }, []);

  return (
    <GetSceneContext.Provider
      value={{
        sceneContext: sceneRef.current,
        camera: cameraRef.current,
        renderer: rendererRef.current
      }}
    >
      <div ref={containerRef}>{children}</div>
    </GetSceneContext.Provider>
  );
}

export default SceneRef;
