import Styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { menuSelected } from '../../reduxhandler/menuredux/menuSlice';
import ProfilLeen from '../../assets/profil_leen.svg';
const LogoStyle = Styled(Link)`
    position:fixed;
    top:30%;
    left:43%;
    transform: translate(-50%, -50%);
    font-family: ${variablesGlobals.fontFamilyLogo};
    color: ${variablesGlobals.darkGreyBack};
    width:100px;
    height:100px;
    z-index: 20;
    text-decoration: none;
    transition: 0.5s;
    @media (min-width: 200px) and (max-width: 1200px) {
        left:70px;
    }
    &:hover{
        filter: brightness(2);
        transition: 0.5s;
    }

    & img {
        position:relative;
        top:-350px;
        left:-100px;
        @media (min-width: 200px) and (max-width: 700px) {
            top:-200px;
    
            left:-20px;
            width:98vw;
    }}
    `;

const LogoLink = Styled.span`
    font-size: 1em;
    font-weight: 400;
    
    `;
const LogoLeen = Styled.span`
    margin:auto;
    font-size: 6em;
    font-weight: 400;
    line-height: 0.8em;
    @media (min-width: 200px) and (max-width: 1200px) {
        font-size: 4em;
    }
    `;

const LogoCom = Styled.span`
    font-size: 1em;
    font-weight: 400;
    `;

function Logo() {
  const dispatch = useDispatch();
  const handleClickMenu = (elementMenu) => {
    dispatch(menuSelected(elementMenu));
  };

  return (
    <>
      <LogoStyle
        to="/a_propos"
        onClick={() => handleClickMenu('About')}
      >
        <LogoLink>Link </LogoLink>
        <LogoLeen>Leen</LogoLeen>
        <LogoCom>.com</LogoCom>
        <img
          src={ProfilLeen}
          alt="Profil Leen"
          width="700px"
        />
      </LogoStyle>
    </>
  );
}

export default Logo;
