import {
  Routes,
  Route,
  useLocation,
  Navigate
} from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Search from '../components/recherchecomponents/Search';
import RecherchePage from '../pages/RecherchePage';
import VisuelPage from '../pages/VisuelPage';
import NotFound from '../pages/NotFound';
import Navigation from '../components/navigation/Navigation';
import { GlobalStyled } from '../style/GlobalStyled';
import { Provider } from 'react-redux';
import store from '../store/store';
import SphericEnvironment from '../3d/utils3d/SphericEnvironment';
import Generator3d from '../3d/utils3d/Generator3d';
import AboutPage from '../pages/AboutPage';
import CarnetPage from '../pages/CarnetPage';
import ArticleDetail from '../components/carnetcomponent/ArticleDetail';
function GetRoutes() {
  const location = useLocation();

  const renderArtComponents =
    location.pathname.startsWith('/');

  return (
    <Provider store={store}>
      {location.pathname !== '/' && (
        <Navigation />
      )}
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* Combine /art and /art/visuel to render the same component */}
        <Route
          path="/art"
          element={<VisuelPage />}
        />
        <Route
          path="/art/visuel"
          element={<VisuelPage />}
        />
        <Route
          path="/recherche"
          element={<RecherchePage />}
        />

        <Route
          path="/ecrits"
          element={<RecherchePage />}
        />
        <Route
          path="/leen/la-muse"
          element={<RecherchePage />}
        />
        <Route
          path="/galerie"
          element={<VisuelPage />}
        />

        <Route
          path="/art/musique"
          element={<div />}
        />

        <Route
          path="/images/*"
          element={
            <Navigate
              to={location.pathname}
              replace
            />
          }
        />
        <Route
          path="/a_propos"
          element={<AboutPage />}
        />

        <Route
          path="/carnet"
          element={<CarnetPage />}
        />

        {/* Route dynamique pour un article */}
        <Route
          path="/article/:title"
          element={<ArticleDetail />}
        />

        <Route path="/*" element={<NotFound />} />
      </Routes>
      {renderArtComponents && (
        <SphericEnvironment />
      )}
      {location.pathname === '/art' && (
        <Generator3d />
      )}
      {location.pathname === '/art/visuel' && (
        <Generator3d />
      )}
      {location.pathname === '/galerie' && (
        <Generator3d />
      )}
    </Provider>
  );
}

export default GetRoutes;
