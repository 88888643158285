import { useDispatch } from 'react-redux';
import { setSearchKeyword } from '../../reduxhandler/articleredux/articleSlice';




function SearchComponent() {
  const dispatch = useDispatch();

  const handleKeywordChange = (e) => {
    dispatch(setSearchKeyword(e.target.value));
  };

  return (
    <input
      type="text"
      placeholder="Rechercher un article..."
      onChange={handleKeywordChange}
    />
  );
}

export default SearchComponent;
