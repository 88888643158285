import React from 'react';
import ImageRenderer from './ImageRenderer'; // Importez le composant ImageRenderer
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';

// Conteneur pour unifier le fond des paragraphes
const TextContainer = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

const Paragraphe = styled.div`
  margin: 0 0 1em 0; /* Réduit l'espace entre les paragraphes */
  line-height: 1.6;
`;

// Style pour l'infobulle des notes
const Tooltip = styled.div`
  background-color: ${variablesGlobals.veryVeryLightGreyBack};
  color: black;
  padding: 5px 10px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  font-size: 0.9em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  white-space: wrap;
`;

const Superscript = styled.sup`
  cursor: pointer;

  &:hover ${Tooltip} {
    display: flex;
    opacity: 1;
  }
`;

const HighlightedParagraph = styled.div`
  font-size: 1.5em; /* Texte plus grand */
  font-weight: bold; /* Gras */
  color: ${variablesGlobals.veryDarkGreyBack}; /* Couleur principale */
  padding: 10px 20px; /* Espacement intérieur */
  margin: 20px 0; /* Espacement extérieur */
  border-radius: 5px; /* Coins arrondis */
  line-height: 1.8; /* Hauteur de ligne augmentée */
`;

// Fonction pour convertir les liens en balises <a>
const convertTextWithLinks = (text) => {
  const parts = text.split(
    /(https?:\/\/[^\s]+)/ // Divise le texte en segments contenant des URL
  );

  return parts.map((part, index) => {
    if (part.match(/https?:\/\/[^\s]+/)) {
      return (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: variablesGlobals.linkColor
          }}
        >
          {part}
        </a>
      );
    }
    return part;
  });
};

// Fonction pour convertir les astérisques en italiques
const convertTextWithItalic = (text) => {
  const parts = text.split(/(\*[^*]+\*)/); // Divise le texte en segments avec et sans astérisques

  return parts.map((part, index) => {
    if (
      part.startsWith('*') &&
      part.endsWith('*')
    ) {
      return (
        <em
          style={{
            color: variablesGlobals.darkGreyBack
          }}
          key={index}
        >
          {part.slice(1, -1).trim()}
        </em>
      );
    }
    return part;
  });
};

// Fonction pour convertir les références de notes de bas de page en exposants avec infobulles
const convertTextWithSuperscript = (
  text,
  notes
) => {
  const parts = text?.split(/(\[note_\d+\])/); // Divise le texte en segments contenant les notes

  return parts.map((part, index) => {
    const noteMatch = part.match(
      /\[note_(\d+)\]/
    ); // Vérifie si c'est une note
    if (noteMatch) {
      const noteNumber = noteMatch[1];
      const noteText = notes
        .find((note) =>
          note.startsWith(`[note_${noteNumber}]`)
        )
        ?.replace(`[note_${noteNumber}]`, '');

      const formattedNoteText =
        convertTextWithItalic(
          noteText || 'Note indisponible'
        ); // Applique les italiques aux astérisques

      return (
        <Superscript key={index}>
          {noteNumber}
          <Tooltip>{formattedNoteText}</Tooltip>
        </Superscript>
      );
    }
    return part;
  });
};

// Fonction pour combiner les conversions italique, exposants, et liens
const convertText = (text, notes) => {
  const withSuperscript =
    convertTextWithSuperscript(text, notes);
  return withSuperscript.map((segment, index) => {
    if (typeof segment === 'string') {
      const withItalic =
        convertTextWithItalic(segment);
      return withItalic.map(
        (subSegment, subIndex) =>
          typeof subSegment === 'string'
            ? convertTextWithLinks(subSegment)
            : subSegment
      );
    }
    return segment;
  });
};
export const parseTextImage = (
  text,
  images,
  notes,
  url
) => {
  const chunks = text?.split(/\n\n/) || [];
  const result = [];
  let currentImageRow = []; // Pour regrouper les images consécutives

  const ImageRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 15px 0;
  `;

  chunks.forEach((chunk, index) => {
    // Vérifie si le paragraphe est encadré par le symbole £
    if (
      chunk.startsWith('£') &&
      chunk.endsWith('£')
    ) {
      result.push(
        <HighlightedParagraph
          key={`highlight-${index}`}
        >
          {chunk.slice(1, -1).trim()}
        </HighlightedParagraph>
      );
      return;
    }

    // Vérifie si le chunk contient une référence à une image
    const imageMatch = chunk.match(
      /\[image_(\d+)\]/
    );

    if (imageMatch) {
      const imageKey = `image_${imageMatch[1]}`;
      const imageMeta =
        images &&
        images.find(
          (img) => img.rang === imageKey
        );

      if (imageMeta) {
        currentImageRow.push(
          <ImageRenderer
            key={`image-${imageKey}`}
            image={imageMeta}
            url={url}
          />
        );

        // Si la dernière image ou si la prochaine ligne ne contient pas d'image
        const nextChunk = chunks[index + 1];
        const isNextImage =
          nextChunk &&
          nextChunk.match(/\[image_(\d+)\]/);

        if (
          !isNextImage ||
          index === chunks.length - 1
        ) {
          result.push(
            <ImageRow key={`row-${index}`}>
              {currentImageRow}
            </ImageRow>
          );
          currentImageRow = []; // Réinitialise pour la prochaine ligne
        }
      }
      return;
    }

    // Si pas de référence d'image ou de style spécial
    result.push(
      <Paragraphe key={`paragraph-${index}`}>
        {convertText(chunk, notes)}
      </Paragraphe>
    );
  });

  return <TextContainer>{result}</TextContainer>;
};
