import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { menuSelected } from '../reduxhandler/menuredux/menuSlice';
import CarnetComponent from '../components/carnetcomponent/CarnetComponent';

function CarnetPage() {
  const title = 'Carnet';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(menuSelected(title));
  }, [dispatch, title]);

  return (
    <>
      <CarnetComponent />
    </>
  );
}

export default CarnetPage;
