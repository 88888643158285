import { useState } from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import styled from 'styled-components';
import { formatUrlMiniature } from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { variablesGlobals } from '../../../style/variables';
import { setModalData } from '../../../reduxhandler/modalredux/modalSlice';

const GalleryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 80px;
  z-index: 100;
  padding: 0 20px;
  margin: auto;
  box-shadow: 2px 2px 4px
    ${variablesGlobals.lightGreyBack};
  border-radius: 20px;
  margin-bottom: 10px;
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 50%;
  }
  && button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: ${variablesGlobals.darkGreyBack};
    height: 80px;
    border-radius: 0 0 0 20px;
    z-index: 100;
    margin: auto;
  }
`;

const Image = styled.div`
  margin: auto;
  position: relative;
  width: 80px;
  height: 60px;
  margin: 10px;
  overflow: hidden;
  && img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: 0.5s;
    object-position: 50% 50%;
  }
  && img:hover {
    width: 150%;
    height: 150%;
    transition: 0.5s;
    object-position: 50% 50%;
  }
`;

const FontAwesomeIconStyle = styled(
  FontAwesomeIcon
)`
  background-color: ${variablesGlobals.darkGreyBack};
  height: 40px;
  width: 30px;
  color: white;
  padding: 10px;
  border-radius: 20px 0 0 20px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    background-color: white;
    color: ${variablesGlobals.darkGreyBack};
    transition: 0.5s;
  }
`;

const FontAwesomeIconStyleRight = styled(
  FontAwesomeIconStyle
)`
  border-radius: 0px 20px 20px 0px;
`;

function ListMiniature() {
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.object3dReducer.filteredList
  );

  const [page, setPage] = useState(0);

  const handleNext = () => {
    setPage(page + 1);
  };

  const handlePrev = (e) => {
    setPage(page - 1);
  };

  const displayPictureModal = (data) => {
    dispatch(setModalData(data));
  };

  const startIndex = page * 5;
  const paginatedImages = data.slice(
    startIndex,
    startIndex + 5
  );
  return (
    <div>
      <GalleryContainer>
        <button
          onClick={handlePrev}
          disabled={page === 0}
        >
          <FontAwesomeIconStyle
            icon={faChevronLeft}
          />
        </button>
        {paginatedImages.map((img, index) => {
          const urlImage =
            formatUrlMiniature(img);
          return (
            <Image
              onClick={() => {
                displayPictureModal(img);
              }}
              key={`imagecontainer-${index}`}
            >
              <img
                key={`img-${index}`}
                src={urlImage}
                alt={`${img.title}`}
              />
            </Image>
          );
        })}
        <button
          onClick={handleNext}
          disabled={startIndex + 5 >= data.length}
        >
          <FontAwesomeIconStyleRight
            icon={faChevronRight}
          />
        </button>
      </GalleryContainer>
    </div>
  );
}

export default ListMiniature;
