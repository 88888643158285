import { motion } from 'framer-motion';
import styled from 'styled-components';
import { variablesGlobals } from '../../style/variables';
import { Helmet } from 'react-helmet-async';

// Style pour le conteneur principal avec Framer Motion
const ResumeStyle = styled(motion.div)`
  height: fit-content;
  width: 100%;
  display: flex;
  padding-bottom: 200px;
  flex-direction: column;
  justify-content: center;
  max-width:100vw;

  align-items: center;
  top: 200px;
  overflow-y: hidden;
  position: absolute;
  @media (min-width: 200px) and (max-width: 1200px) {
    top:180px;
  }
`;

// Span style
const SpanMark = styled.span`
  font-weight: 700;
  font-size: larger;
`;

// Style pour chaque texte avec Framer Motion
const TexteStyle = styled(motion.div)`
  height: fit-content;
  width: 70%;
  right: 0;
  bottom: 0;
  margin: 5px auto;
  border-radius: 25px;
  padding: 15px;
  box-sizing: border-box;
  background-color: ${variablesGlobals.opacityWhite};
  color: ${variablesGlobals.darkGreyBack};
  font-family: ${variablesGlobals.fontFamilyWriting};
  position: relative;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8em;
  }

  @media (min-width: 200px) and (max-width: 1200px) {
    width: 100%;
    font-size: small;
  }
`;

// Variants pour gérer l'animation en cascade
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4 // Délai entre chaque enfant
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 60 },
  visible: { opacity: 1, y: 0 }
};

function Resume() {
  return (
    <>
      <Helmet>
        <title>
          À Propos - Leen (Yolkin Cocud)
        </title>
        <meta
          name="description"
          content="Yolkin Cocud, dit Leen, artiste, auto-entrepreneur, concepteur et développeur de logiciels. Un site web qui reflète mon univers artistique."
        />
        <meta
          name="keywords"
          content="Leen, Yolkin Cocud, développeur, artiste, arts, musique, recherche, portfolio, conception logicielle, UX design"
        />
        <meta
          name="author"
          content="Leen (Yolkin Cocud)"
        />
        <meta
          property="og:title"
          content="À Propos - Leen (Yolkin Cocud)"
        />
        <meta
          property="og:description"
          content="Leen (Yolkin Cocud) : auto-entrepreneur, concepteur de logiciels, artiste"
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:url"
          content="https://linkleen.com/a-propos"
        />
      </Helmet>
      <ResumeStyle
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <TexteStyle variants={itemVariants}>
          <h1>À Propos</h1>
        </TexteStyle>

        <TexteStyle variants={itemVariants}>
          <p>Leen (Yolkin Cocud)</p>
          <p>Auto-Entrepreneur</p>
          <p>
            Concepteur et Développeur de Logiciels
          </p>
          <p>Artiste</p>
        </TexteStyle>

        <TexteStyle variants={itemVariants}>
          <p>
            J'ai conçu et développé ce website
            pour qu'il me suit.
          </p>
          <p>
            Flexible, il est configuré pour être
            en intégration et déploiement continu
            via GitHub Action. Au plus près de mon
            évolution, cela me permet de déployer
            à chacune de mes idées. Chaque élément
            de navigation a été pensé pour offrir
            une expérience fluide et immersive. Il
            est responsive.
          </p>
        </TexteStyle>

        <TexteStyle variants={itemVariants}>
          <p>
            L'arrière-plan se mouve selon la
            position de la souris, je l'ai
            programmé avec la bibliothèque
            Javascript pour la 3D, ThreeJS sur une
            de mes photos du ciel du nord que j'ai
            adapté en panorama.
          </p>
        </TexteStyle>

        <TexteStyle variants={itemVariants}>
          <p>
            J'ai appliqué une esthétique épurée,
            entièrement réalisée en nuances de
            gris. Ce choix chromatique, sobre,
            contraste volontairement avec mon
            tempérament artistique souvent marqué
            par des œuvres vibrantes et colorées,
            visible en partie dans la galerie.
            L'utilisation du gris n'est pas un
            simple choix esthétique, elle reflète
            ma démarche, minimaliste, où chaque
            nuance évoque le crayon graphite,
            l'esquisse, l'ébauche, les premières
            perspectives de tous les concepts qui
            nous tiennent à cœur.
          </p>
          <p>
            Ce contraste entre simplicité visuelle
            et richesse de mon univers souligne
            l'originalité de ce travail : une
            interface discrète, qui témoigne en
            partie de ma maîtrise des outils
            technologiques et du design UX. Une
            dualité entre sobriété et créativité,
            là où rigueur technique coexiste avec
            une profonde sensibilité artistique.
          </p>
        </TexteStyle>
      </ResumeStyle>
    </>
  );
}

export default Resume;
