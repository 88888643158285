import './App.css';
import GetRoutes from './getroutes/GetRoutes';
import { Provider } from 'react-redux';
import store from './store/store';
import SceneRef from './3d/utils3d/Scene3d';
import styled from 'styled-components';
import { GlobalStyled } from './style/GlobalStyled';
import Footer from './components/menuprincipal/Footer';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store/store';
import { HelmetProvider } from 'react-helmet-async';
import AutoRSSGenerator from './outils/autoRssGenerator';
import BackButtonWrapper from './components/BackButtonWrapper';

const SceneThree = styled(SceneRef)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  max-width: 100vw;
  overflow: hidden;
  top: 0px;
  left: 0px;
  z-index: 1;
`;
function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        {' '}
        <GlobalStyled />
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          {' '}
          <BackButtonWrapper>
            <SceneThree>
              <GetRoutes />
            </SceneThree>
            <Footer />
          </BackButtonWrapper>
        </PersistGate>
      </Provider>{' '}
      {/* <AutoRSSGenerator /> */}
    </HelmetProvider>
  );
}

export default App;
