import datasMock from '../data/art';
import { object3dListAction } from '../reduxhandler/object3dredux/object3dSlice';
import { setData } from '../reduxhandler/ecritredux/ecritSlice';
import dataJePenseMock from '../data/je-pense-donc-je-cree.json';
import dataInspirationMock from '../data/inspiration.json';

export function getDatas(dispatch) {
  if (datasMock) {
    dispatch(object3dListAction(datasMock));
  } else {
    console.error('datasMock is undefined');
  }
}

// Fonction générique pour charger des données
const loadData = (dispatch, type, mockData) => {
  if (!mockData || !mockData.data) {
    console.error(
      `Les données pour ${type} sont absentes ou mal formatées.`
    );
    return;
  }
  try {
    dispatch(
      setData({ type, data: mockData.data })
    );
  } catch (error) {
    console.error(
      `Erreur lors du chargement des données pour ${type}:`,
      error
    );
  }
};

// Fonction pour charger les données "JePense"
export const getDatasJePense = (dispatch) => {
  loadData(dispatch, 'JePense', dataJePenseMock);
};

// Fonction pour charger les données "Inspiration"
export const getDatasInspiration = (dispatch) => {
  loadData(
    dispatch,
    'Inspiration',
    dataInspirationMock
  );
};
