export const listTags = [
  'Musique',
  'Peinture',
  'Vidéo',
  'Recherche',
  'Evènement',
  'Note',
  'Sytrileen',
  'Lucifine',
  'Numérique',
  'Logiciels',
  'Machine Learning'
];
