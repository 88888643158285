import {
  useEffect,
  useContext,
  useState,
  useRef
} from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import * as THREE from 'three';
import GetSceneContext from './SceneContext';
import { getDatas } from '../../services/getDatas';
import createImage3D from '../components3d/Image3d';
import {
  toggleModalDisplay,
  setModalData
} from '../../reduxhandler/modalredux/modalSlice';
import { resetState } from '../../reduxhandler/object3dredux/object3dSlice';
import { addMouseOverRotation } from '../animation3d/evenementMouse3D';

function Generator3d() {
  const { sceneContext, camera, renderer } =
    useContext(GetSceneContext);
  const dispatch = useDispatch();
  const data = useSelector(
    (state) => state.object3dReducer.filteredList
  );

  const [isLoading, setIsLoading] =
    useState(false);
  const object3Ds = useRef([]);
  const cleanupFunctions = useRef([]);

  useEffect(() => {
    getDatas(dispatch);
    return () => {
      clearScene();
      dispatch(resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (data?.length === 0) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    clearScene();
    loadDataAndCreateObjects(data).then(() =>
      setIsLoading(false)
    );
  }, [data]);

  const clearScene = () => {
    object3Ds.current.forEach(disposeObject);
    cleanupFunctions.current.forEach((cleanup) =>
      cleanup()
    ); // Appel des fonctions de nettoyage
    cleanupFunctions.current = [];
    object3Ds.current = [];
  };

  const disposeObject = (object3D) => {
    if (object3D.material.map)
      object3D.material.map.dispose();
    object3D.material.dispose();
    object3D.geometry.dispose();
    sceneContext.remove(object3D);
  };

  const loadDataAndCreateObjects = async (
    data
  ) => {
    const totalObjects = data?.length;
    const radius = 10;
    const offset = 2 / totalObjects;
    const increment =
      Math.PI * (3 - Math.sqrt(5));

    await Promise.all(
      data.map(async (objData, i) => {
        let y = i * offset - 1 + offset / 2;
        const r = Math.sqrt(1 - y * y);

        const phi = i * increment;

        const x = Math.cos(phi) * r;
        const z = Math.sin(phi) * r;

        const position = new THREE.Vector3(
          x * radius,
          y * radius,
          z * radius
        );
        const object3D = await createImage3D(
          objData,
          position
        );
        object3D.userData = objData;
        object3D.lookAt(
          new THREE.Vector3(0, 0, 0)
        );
        sceneContext.add(object3D);
        object3Ds.current.push(object3D);

        const cleanup = addMouseOverRotation(
          object3D,
          camera,
          sceneContext,
          renderer,
          () => {
            dispatch(setModalData(objData));
            dispatch(toggleModalDisplay());
          }
        );
        cleanupFunctions.current.push(cleanup);
      })
    );
  };

  if (isLoading) {
    return <div>Loading 3D Objects...</div>;
  }

  return null;
}

export default Generator3d;
