import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faXmark,
  faTag
} from '@fortawesome/free-solid-svg-icons';
import styled, {
  keyframes
} from 'styled-components';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { toggleModalDisplay } from '../../reduxhandler/modalredux/modalSlice';
import { variablesGlobals } from '../../style/variables';
import ListMiniature from './listminiature/ListMiniature';
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

const ModalWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  max-width:100vw;

  left: 0;
  z-index: 20000;
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${variablesGlobals.veryVeryLightGreyBack};
  border: none;
  padding: 20px;
  width: 70%;
  height: 90%;
  overflow: auto;
  border-radius: 20px;
  overflow: hidden;
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const ButtonClose = styled.button`
  position: absolute;
  border-radius: 50%;
  top: 0;
  right: 0;
  padding: 2px 10px;
  font-size: 2rem;
  background-color: transparent;
  color: ${variablesGlobals.darkGreyBack};
  border: none;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: ${variablesGlobals.darkGreyBack};
    color: white;
    transition: 0.5s;
  }
`;

const ImageLegendContain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  border-radius: 20px;
  && p {
    font-weight: 400;
    font-size: 1.3em;
    color: ${variablesGlobals.darkGreyBack};
    z-index: 1000;
    @media (min-width: 200px) and (max-width: 1200px) {
      font-size: 0.6em;
    }
  }
`;

const Image = styled.img`
  object-fit: contain;
  width: 70%;

  height: 70%;
  @media (min-width: 200px) and (max-width: 1200px) {
    width: 100%;
  }
`;

const TagContain = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  @media (min-width: 200px) and (max-width: 1200px) {
    flex-direction: column;
    position: absolute;
    z-index: 500;
    top: 0;
    left: 0;
    width: 20%;
  }
`;

const TagParent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: fit-content;
  flex-wrap: nowrap;
  padding: 10px;
  @media (min-width: 200px) and (max-width: 1200px) {
    flex-direction: column;
    padding: 2px;
  }
`;

const Tag = styled.p`
  font-weight: 400;
  font-size: 1em;
  padding: 3px 20px;
  color: white;
  background-color: ${variablesGlobals.darkGreyBack};
  border-radius: 20px;
  cursor: pointer;
  @media (min-width: 200px) and (max-width: 1200px) {
    font-weight: 400;
    font-size: 0.5em;
    padding: 1px 10px;
  }
`;

const FontAwesomeIconStyle = styled(
  FontAwesomeIcon
)`
  color: ${variablesGlobals.darkGreyBack};
  font-size: 1.5em;
  margin-left: 10px;
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.5em;
    margin: auto;
  }
`;

const TextStyle = styled.p`
  font-weight: 400;
  white-space: pre-wrap;
  font-size: 1em;

  box-shadow: 2px 2px 4px
    ${variablesGlobals.lightGreyBack};
  @media (min-width: 200px) and (max-width: 1200px) {
    font-size: 0.7em;
  }
`;
const spin = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border-top: 8px solid #ffffff; /* Couleur de la bordure du haut */
  border-radius: 50%; /* Forme en cercle */
  width: 60px; /* Largeur du spinner */
  height: 60px; /* Hauteur du spinner */
  animation: ${spin} 2s linear infinite; /* Animation de rotation */
`;

const LoaderContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2000;
`;

const LoadingIndicator = () => (
  <LoaderContainer>
    <Spinner>...</Spinner>
  </LoaderContainer>
);

function ModalDisplayImages() {
  const dispatch = useDispatch();
  const displayModal = useSelector(
    (state) => state.modalReducer.displayModal
  );
  const data = useSelector(
    (state) => state.modalReducer.dataSelected
  );
  const [isLoading, setIsLoading] =
    useState(true); // État pour gérer le chargement

  const onClose = () => {
    dispatch(toggleModalDisplay());
  };

  const urlImage = 'images' + data?.image;
  const widthImage = data?.dimensionVirtuel.dX;
  const heightImage = data?.dimensionVirtuel.dY;
  const legendImage =
    data?.author +
    ', ' +
    data?.title +
    ', ' +
    data?.date +
    ', ' +
    data?.technic;
  const tagImage = data?.tags;
  const textImage = data?.text;

  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  const blockClick = (e) => {
    e.stopPropagation();
  };

  if (!displayModal) {
    return null;
  }

  return (
    <>
      {' '}
      {/* Helmet pour la SEO dynamique */}
      <Helmet>
        <title>{data?.title || ''}</title>
        <meta
          name="description"
          content={data?.tags || ''}
        />
        <meta
          property="og:title"
          content={data?.title || ''}
        />
        <meta
          property="og:description"
          content={data?.tags || ''}
        />
        <meta
          property="og:image"
          content={urlImage || ''}
        />
      </Helmet>
      <ModalWrapper
        onClick={(e) => blockClick(e)}
      >
        <ModalContent>
          <ButtonClose onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} />
          </ButtonClose>
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <ImageLegendContain>
              <Image
                src={'../' + urlImage}
                width={widthImage}
                height={heightImage}
              />
              <p>{legendImage}</p>
              <TextStyle>{textImage}</TextStyle>
            </ImageLegendContain>
          )}
          <TagContain>
            {tagImage?.map((tag, index) => (
              <TagParent key={index}>
                <FontAwesomeIconStyle
                  icon={faTag}
                />
                <Tag key={index}>{tag}</Tag>
              </TagParent>
            ))}
          </TagContain>
          <ListMiniature />
        </ModalContent>
      </ModalWrapper>
    </>
  );
}

export default ModalDisplayImages;
